import AsyncStatus from "../../../utils/async";
import PasswordForm from "../../ui/PasswordForm.vue";
import NotificationsMixin from "../../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
export default mixins(NotificationsMixin).extend({
    components: {
        PasswordForm: PasswordForm,
    },
    props: {
        process: {
            type: Function,
            required: true,
        },
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    methods: {
        onProcess: function (password) {
            var _this = this;
            return this.process(password).then(function () {
                _this.displaySuccessMessage(_this.$t("Your account has been created! Please sign in"));
                _this.$router.push("/sign-in");
            });
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Save details for next time").toString(),
        };
    },
});

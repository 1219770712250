export var resetFormValidation = function (component) {
    component.$validator.pause();
    component.$nextTick(function () {
        component.$validator.errors.clear();
        component.$validator.fields.items.forEach(function (field) {
            return field.reset();
        });
        component.$validator.fields.items.forEach(function (field) {
            return component.$validator.errors.remove(field.name);
        });
        component.$validator.resume();
    });
};

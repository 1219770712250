import Vue from "vue";
import { resetFormValidation } from "../../utils/form";
export default Vue.extend({
    props: {
        status: {
            type: Object,
            required: true,
        },
        process: {
            type: Function,
            required: true,
        },
        submitLabel: {
            type: String,
            required: true,
        },
        workingSubmitLabel: {
            type: String,
            required: true,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            password: "",
            confirmPassword: "",
        };
    },
    computed: {
        itemsClass: function () {
            if (this.horizontal) {
                return "w-50";
            }
            return undefined;
        },
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.status.run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                return _this.process(_this.password).then(function () {
                    _this.password = "";
                    _this.confirmPassword = "";
                    resetFormValidation(_this);
                });
            }));
        },
    },
});
